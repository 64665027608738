import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import APIS from "../../constants/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import moment from "moment";
import useLazyHook from "../../customHooks/useLazyQuery";

type Props = {};
const DashboardComponent: any = Loadable({
  loader: () => import("../../components/Dashboard"),
  loading: Loader,
});

const Dashboard = () => {
  const currentFormattedDate = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [monthYear, setMonthYear] = useState({
    year: moment(new Date()).format("YYYY"),
    month: moment(new Date()).format("MM"),
  });
  const [dateViewType, setDateViewType] = useState("weeklyView");
  const [value, setValue] = useState(currentFormattedDate);
  const [data, setData] = useState([]);

  const { isLoading: dashBoardEmailSmsLoading, data: dashBoardEmailSmsData } =
    useGetHook({
      queryKey: ["Dashboard-email-sms"],
      url: `${APIS.DASHBOARD_EMAIL_SMS}`,
      params: {},
    });

  const {
    isLoading: dashBoardReportWeeklyViewLoading,
    data: dashBoardReportWeeklyViewData,
    refetch: lazyWeeklyView,
  } = useLazyHook({
    queryKey: ["weekly-View"],
    url: `${APIS.DASHBOARD_WEEKLY_VIEW}`,
    params: {
      start_date: startDate,
      end_date: endDate,
    },
    isEnabled: !!startDate && !!endDate,
  });

  const {
    isLoading: dashBoardReportMonthlyViewLoading,
    data: dashBoardReportMonthlyViewData,
    refetch: lazymonthlyView,
  } = useLazyHook({
    queryKey: ["monthly-View"],
    url: `${APIS.DASHBOARD_MONTHLY_VIEW}`,
    params: {
      year: monthYear.year,
      month: monthYear.month,
    },
    isEnabled:
      dateViewType === "monthlyView" && !!monthYear.year && !!monthYear.month,
  });
  const {
    isLoading: dashBoardReportYearlyViewLoading,
    data: dashBoardReportYearlyViewData,
    refetch: lazyYearlyView,
  } = useLazyHook({
    queryKey: ["Yearly-View"],
    url: `${APIS.DASHBOARD_YEARLY_VIEW}`,
    params: {
      year: monthYear.year,
    },
    isEnabled: dateViewType === "yearlyView" && !!monthYear.year,
  });

  useEffect(() => {
    const weekDayList = Array.from(Array(7).keys()).map((idx) => {
      const d = new Date(value);
      d.setDate(d.getDate() - d.getDay() + idx);
      return d;
    });
    setStartDate(moment(weekDayList[0]).format("YYYY-MM-DD"));
    setEndDate(moment(weekDayList[6]).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (dateViewType === "weeklyView" && dashBoardReportWeeklyViewData) {
      setData(dashBoardReportWeeklyViewData);
    } else if (
      dateViewType === "monthlyView" &&
      dashBoardReportMonthlyViewData
    ) {
      setData(dashBoardReportMonthlyViewData);
    } else if (dateViewType === "yearlyView" && dashBoardReportYearlyViewData) {
      setData(dashBoardReportYearlyViewData);
    }
  }, [
    dateViewType,
    dashBoardReportWeeklyViewData,
    dashBoardReportMonthlyViewData,
    dashBoardReportYearlyViewData,
  ]);

  useEffect(() => {
    if (dateViewType === "weeklyView" && startDate && endDate) {
      lazyWeeklyView();
    } else if (dateViewType === "monthlyView" && monthYear) {
      lazymonthlyView();
    } else if (dateViewType === "yearlyView" && monthYear) {
      lazyYearlyView();
    }
  }, [dateViewType, startDate, endDate, monthYear]);

  useEffect(() => {
    if (dateViewType === "weeklyView") {
      const weekDayList = Array.from(Array(7).keys()).map((idx) => {
        const d = new Date(value);
        d.setDate(d.getDate() - d.getDay() + idx);
        return d;
      });
      setStartDate(moment(weekDayList[0]).format("YYYY-MM-DD"));
      setEndDate(moment(weekDayList[6]).format("YYYY-MM-DD"));
    } else if (dateViewType === "monthlyView") {
      setMonthYear({
        year: moment(new Date(value)).format("YYYY"),
        month: moment(new Date(value)).format("M"),
      });
    } else if (dateViewType === "yearlyView") {
      setMonthYear({
        year: moment(new Date(value)).format("YYYY"),
        month: moment(new Date(value)).format("M"),
      });
    }
  }, [dateViewType, value]);
  
  return (
    <>
      <DashboardComponent
        dashBoardEmailSmsLoading={dashBoardEmailSmsLoading}
        dashBoardEmailSmsData={dashBoardEmailSmsData}
        dateViewType={dateViewType}
        setDateViewType={setDateViewType}
        data={data ?? []}
        loading={dashBoardReportWeeklyViewLoading}
        setValue={setValue}
        value={value}
      />
    </>
  );
};

export default Dashboard;
